<template>
  <main>

    <!-- Header -->
    <header class="view-admin__header">
      <div class="container">
        <actions-button
          class="view-admin__back"
          icon-pre="arrow-left"
          :href="{ name: 'sayl-front-catalog.catalog' }"
          size="s">{{ $t('resto.back') }} </actions-button>

        <div class="view-admin__infos">
          <div class="view-admin__meta">
            <h2 class="view-admin__overline">{{ $t('resto.burger_actions_admin') }}</h2>
          </div>
          <h1 class="view-admin__title">{{ shop.name }}</h1>
        </div>
      </div>
    </header>

    <!-- Body -->
    <section class="view-admin__body">
      <div class="container">

        <!-- Table selection -->
        <article class="view-admin__article view-admin__tables">
          <header class="view-admin__article-header">
            <h3 class="view-admin__article-title">{{ $t('resto.admin_table') }}</h3>
            <p class="view-admin__article-description">{{ $t('resto.admin_table_description') }}</p>
          </header>

          <div v-if="tables" class="view-admin__artice-body">
            <ul class="tables__list">
              <li
                :class="{ 'tables__item': true, '-is-active': table == entry.value }"
                v-for="entry in tables"
                :key="entry.value">
                <button
                  :disabled="!entry.active"
                  class="tables__table"
                  @click="onConfirm(entry)">{{ entry.label }}</button>
              </li>
            </ul>
          </div>
        </article>

        <!-- Reset -->
        <article class="view-admin__article">
          <header class="view-admin__article-header">
            <h3 class="view-admin__article-title">{{ $t('resto.admin_reset') }}</h3>
            <h3 class="view-admin__article-description">{{ $t('resto.admin_reset_description') }}</h3>
          </header>

          <div class="view-admin__article-body">
            <actions-button
              @click="onReset"
              intent="danger"
            >{{ $t('resto.admin_reset_action') }}</actions-button>
          </div>
        </article>
      </div>
    </section>

  </main>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { EatinMixin } from '@sayl/front-core'
import { basil } from '@spices/basil'

export default {
  name: 'AdminView',

  mixins: [ EatinMixin ],

  inject: [
    '$user',
    '$cart',
    '$embed',
    '$localStorage'
  ],

  data() {
    return {
      table: null,
      error: []
    }
  },

  computed: {
    ...mapState({
      service: state => state['sayl-front.service'].service,
      shops: state => state.bootstrap.data.extensions.oat,
    }),

    embed() {
      return this.$basil.get(this.$embed, 'embed.model')
    },

    shop() {
      return this.$basil.get(this.$embed, 'shop.model')
    },

    isMultiShopsEnabled() {
      let shop = this.shops?.find(s => s.shop_id === this.embed.shopId)

      return shop?.is_multi_shops_enabled ?? false
    },
  },

  methods: {
    ...mapActions({
      changeTable: 'sayl-front.service-eatin/changeTable'
    }),

    reset() {
      return this.$user.reset
    },

    onConfirm(value) {
      if(basil.isNil(value)){
        this.error = [this.$t('resto.table_error')]
        return
      }

      this.changeTable({ item: value })
        .then(() => {
          this.$router.push({
            name: 'sayl-front-catalog.catalog',
            params: {
              embed: this.embed.id,
              service: this.service.name
            }
          }).catch(() => {})
        })
        .catch((e) => $console.error(e))
    },

    onReset() {
      this.$localStorage.table = null;
      this.$localStorage.cart = null;
      // this.$localStorage.tracing = null;

      this.reset()
        .then(() => this.$cart.init({
          shopId: this.shop.id,
          service: this.service.name,
          catalogId: this.service.menuId,
          embedId: this.embed.id,
          enrich: this.isMultiShopsEnabled ? 1 : 0
        }))
        .then(() =>
          this.$router.push({
            name: 'sayl-front-catalog.catalog',
            params: {
              embed: this.$localStorage.embed,
              service: this.$localStorage.service
            }
          })
          .catch((e) => $console.error(e))
        );
    }
  },

  mounted() {
    if(this.$localStorage.mode !== 'tt' || this.$localStorage.admin !== true ) {
      this.$router.push({ name: 'sayl-front-catalog.catalog' })
        .catch(() => {})
    }

    this.table = this.$localStorage.table
  },

  beforeDestroy() {
    this.$localStorage.admin = null
  }
}
</script>
